import generateRoutes from './utils/routing-utils';

export const ENV = process.env.REACT_APP_ENV;

// Routing
export const Routes = generateRoutes({
  ARTICLE_SEARCH: '/article/search',
  ARTICLE_CREATE: '/article/create',
  TEST: '/test',
  LOGIN: '/login',
  ARTICLE_DETAIL: '/article/:articleId',
  ARTICLE_VIEW: '/article/:articleId/version/:versionId/view',
  CLUSTER_SEARCH: '/cluster/search',
  CLUSTER_DETAIL: '/cluster/:clusterId',
  REPORTS: '/reports',
  DISTRIBUTION: '/distribution',
  DISTRIBUTION_DETAIL: '/distribution/:distributionId',
  WIP_DETAIL: '/workflow/status/:articleId/:processId',
  COMPLETED_WORK_SEARCH: '/workflow/completed',
  ALL_WORK_SEARCH: '/workflow/all',
  ALL_WORK_SEARCH_DEBUG: '/workflow/all/debug',
  WIP_CREATE: '/workflow/create',
  MY_WORK: '/workflow/mywork',
  MY_WORK_DEBUG: '/workflow/mywork/debug',
  INDEX_SEARCH: '/indexing',
  HELP: '/help',
  HELP_OXYGEN: '/help/oxygen',
  HELP_SPECIAL: '/help/special-characters',
  HELP_STYLEBOOK: '/help/stylebook',
  HELP_WORKFLOW: '/help/workflow-diagrams',
  PROOF: '/proof/:processId',
  GROUPS: '/groups',
  GROUP_DETAIL: '/group/:groupId',
  USER_DETAIL: '/user/:userId/:isUser',
  USERS: '/users',
  INSTITUTIONAL_STATUS: '/p2p/institutional',
  CONSUMER_STATUS: '/p2p/consumer',
  INTERNATIONAL_STATUS: '/p2p/international',
  BDS_STATUS: '/p2p/bds',
  SYNDICATION_STATUS: '/p2p/syndication',
  FEATURE_SEARCH: '/feature/search',
  FEATURE_CREATE: '/feature/create',
  FEATURE_DETAIL: '/feature/:featureId',
  FEATURE_VIEW: '/feature/:featureId/view',
  RECENT_ARTICLES: '/recent/article',
  RECENT_FEATURES: '/recent/feature',
});

// MAM Base URL
export const MAM_BASE_URL = process.env.REACT_APP_MAM_BASE_URL;

// AM User Guide Link
export const AM_USER_GUIDE_LINK = process.env.REACT_APP_AM_USER_GUIDE_LINK;

// Full Text Search URL
export const FULL_TEXT_URL = process.env.REACT_APP_FULL_TEXT_SEARCH;

// Editorial Resource Center
export const RESOURCE_CENTER_LINK = 'https://drive.google.com/drive/folders/1FjhTnILBknWUllnje1zAjEz0xI44eV8y';

// Feature Calendar
export const FEATURE_CALENDAR_LINK = 'http://features.ad.eb.com:8086/';

export const REPORT_TOOL_LINK = 'http://jasper.ad.eb.com:8188/jasperserver ';

// APIs
export const BESSIE_API = process.env.REACT_APP_BESSIE_API;
export const EDITORIAL_API = process.env.REACT_APP_EDITORIAL_API;
export const ELSIE_API = process.env.REACT_APP_ELSIE_API;

// Auth Cookie
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const COOKIE_EXPIRATION = 1;
export const COOKIE_TICKET_NAME = 'beamTicket';
export const COOKIE_TICKET_ENCODED_NAME = 'beamEncodedTicket';
export const COOKIE_USER_NAME = 'beamUser';
export const COOKIE_ATTRIBUTES = {
  expires: COOKIE_EXPIRATION,
  ...(COOKIE_DOMAIN && { domain: COOKIE_DOMAIN }),
};

export const ARTICLE_TYPE_ID_REQUIRE_SUBJECTS = [
  1, 147, 31, 38, 94, 108, 106, 137, 45, 80, 98, 18, 55, 57, 15, 150, 152,
];

export const API_MAX_PER_PAGE = 1000;

// Table config defaults
export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const DEFAULT_TABLE_PAGE_INDEX = 0;
