import React, { useState } from 'react';
import { NavLink as RRDNavLink } from 'react-router-dom';
import classnames from 'classnames';
import {
  Collapse,
  Nav,
  Navbar,
  NavLink,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { MdExitToApp, MdPersonOutline, MdMenu } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';

import {
  Routes,
  MAM_BASE_URL,
  REPORT_TOOL_LINK,
  RESOURCE_CENTER_LINK,
  FEATURE_CALENDAR_LINK,
  FULL_TEXT_URL,
} from '../../App.constants';
import beamLogoDark from '../../assets/beamLogoDark.png';
import styles from './Header.module.scss';
import useAuthApi from '../../hooks/useAuthApi';
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';

const Header = () => {
  const { header, activeNavLink, menu, activeHeaderLink, secondnav } = styles;
  const [isOpen, setIsOpen] = useState(false);
  const { signOutUser, signingOut, userDataFromStore } = useAuthApi();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const userId = String(userDataFromStore?.userId);
  const username = userDataFromStore?.username;
  const userGroups = userDataFromStore?.groups;

  let flag = false;

  let b2Flag = false;

  userGroups?.map((group) => {
    if (group.groupId === 1033) {
      flag = true;
    }

    if (group.groupId === 1153) {
      b2Flag = true;
    }

    return flag;
  });

  const handleLogoutClick = () => {
    signOutUser();
  };

  return (
    <>
      <header>
        <Navbar
          color="primary"
          expand="xs"
          className={classnames(header, 'py-0 d-flex justify-content-between align-items-center')}
        >
          <Collapse navbar>
            <Nav navbar className="w-100">
              <NavItem className="d-none d-md-flex align-items-center">
                <img className="navbar-brand" src={beamLogoDark} alt="link to beam" height="30" />
              </NavItem>

              <NavItem>
                <NavLink activeClassName={activeHeaderLink} to="/" tag={RRDNavLink}>
                  <span className="d-none d-sm-block mb-2">Article Management</span>
                  <span className="d-block d-sm-none mb-2">AM</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <a href={MAM_BASE_URL} target="_self" className="nav-link">
                  <span className="d-none d-sm-block">Media Asset Management</span>
                  <span className="d-block d-sm-none ">MAM</span>
                </a>
              </NavItem>

              <UncontrolledDropdown nav inNavbar className="ms-md-auto">
                <DropdownToggle nav caret className="bold text-white">
                  Profile
                </DropdownToggle>

                <DropdownMenu end>
                  <DropdownItem>
                    <NavLink
                      className="text-primary p-0"
                      activeClassName={activeNavLink}
                      to={Routes.USER_DETAIL.toLink({ userId, isUser: 'true' })}
                      tag={RRDNavLink}
                    >
                      <MdPersonOutline size={18} className="me-2" />
                      {username}
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem
                    className="d-flex align-items-center justify-content-start text-primary"
                    onClick={() => setShowChangePasswordModal(true)}
                  >
                    <RiLockPasswordLine size={18} className="me-2" />
                    Change Password
                  </DropdownItem>
                  <DropdownItem
                    disabled={signingOut}
                    className="d-flex align-items-center justify-content-start text-primary"
                    onClick={handleLogoutClick}
                  >
                    <MdExitToApp size={18} className="me-2" /> {signingOut ? 'Signing out...' : 'Sign out'}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
          <Button color="primary" className="d-md d-md-none p-0" onClick={() => setIsOpen(!isOpen)}>
            <MdMenu size={32} />
          </Button>
        </Navbar>

        <Navbar light expand="md" className={classnames(secondnav, 'p-0')}>
          <Collapse isOpen={isOpen} navbar className={menu}>
            <Nav navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Article
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      className="text-primary p-0"
                      activeClassName={activeNavLink}
                      to={Routes.ARTICLE_SEARCH.toLink()}
                      tag={RRDNavLink}
                    >
                      Article Search
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem>
                    <NavLink
                      className="text-primary p-0"
                      activeClassName={activeNavLink}
                      to={Routes.ARTICLE_CREATE.toLink()}
                      tag={RRDNavLink}
                    >
                      Create New Article
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.CLUSTER_SEARCH.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                    >
                      Cluster Search
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.INDEX_SEARCH.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                    >
                      Indexing
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.RECENT_ARTICLES.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                    >
                      Recently Updated Articles
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {b2Flag && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Feature
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName={activeNavLink}
                        to={Routes.FEATURE_SEARCH.toLink()}
                        tag={RRDNavLink}
                      >
                        Feature Search
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName={activeNavLink}
                        to={Routes.FEATURE_CREATE.toLink()}
                        tag={RRDNavLink}
                      >
                        Create New Feature
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={FEATURE_CALENDAR_LINK}
                        className="p-0 nav-link"
                      >
                        Calendar
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink
                        activeClassName={activeNavLink}
                        to={Routes.RECENT_FEATURES.toLink()}
                        tag={RRDNavLink}
                        className="text-primary p-0"
                      >
                        Recently Updated Features
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Workflow
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.MY_WORK.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                    >
                      My Work
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.ALL_WORK_SEARCH.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                    >
                      All Work
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.COMPLETED_WORK_SEARCH.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                    >
                      Completed Work
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Tools
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      href={FULL_TEXT_URL}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-primary p-0"
                    >
                      Full-Text Search
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.DISTRIBUTION.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                    >
                      Distributions
                    </NavLink>
                  </DropdownItem>

                  {flag && (
                    <UncontrolledDropdown nav inNavbar tag="div">
                      <DropdownToggle nav caret className="ms-1">
                        Admin
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>
                          <NavLink
                            activeClassName={activeNavLink}
                            to={Routes.GROUPS.toLink()}
                            tag={RRDNavLink}
                            className="text-primary p-0"
                            exact
                          >
                            Groups
                          </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                          <NavLink
                            activeClassName={activeNavLink}
                            to={Routes.USERS.toLink()}
                            tag={RRDNavLink}
                            className="text-primary p-0"
                            exact
                          >
                            Users
                          </NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}

                  <UncontrolledDropdown nav inNavbar tag="div">
                    <DropdownToggle nav caret className="ms-1">
                      P2P Dashboard
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.CONSUMER_STATUS.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          Consumer
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.INSTITUTIONAL_STATUS.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          Institutional
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.INTERNATIONAL_STATUS.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          International
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.SYNDICATION_STATUS.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          Syndication
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.BDS_STATUS.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          BDS
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Reports
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      href={REPORT_TOOL_LINK}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-primary p-0"
                    >
                      Report Tool
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.REPORTS.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                      exact
                    >
                      Generated Reports
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Help
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      activeClassName={activeNavLink}
                      to={Routes.HELP.toLink()}
                      tag={RRDNavLink}
                      className="text-primary p-0"
                      exact
                    >
                      User Guide
                    </NavLink>
                  </DropdownItem>

                  <UncontrolledDropdown nav inNavbar tag="div">
                    <DropdownToggle nav caret className="ms-1">
                      Documents
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.HELP_OXYGEN.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          Oxygen User Guide
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.HELP_SPECIAL.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          Special Characters List
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.HELP_STYLEBOOK.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          Britannica Stylebook
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink
                          activeClassName={activeNavLink}
                          to={Routes.HELP_WORKFLOW.toLink()}
                          tag={RRDNavLink}
                          className="text-primary p-0"
                          exact
                        >
                          Workflow Diagrams
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={RESOURCE_CENTER_LINK}
                          className="p-0 nav-link"
                        >
                          Editorial Resource Center
                        </a>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
      {showChangePasswordModal && <ChangePasswordModal toggle={() => setShowChangePasswordModal(false)} />}
    </>
  );
};

export default Header;
