import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';

import ReportsPage from './pages/ReportsPage/ReportsPage';
import { ENV, Routes } from './App.constants';
import ArticleSearchPage from './pages/ArticleSearchPage/ArticleSearchPage.lazy';
import ArticleCreatePage from './pages/ArticleCreatePage/ArticleCreatePage.lazy';
import TestPage from './pages/TestPage/TestPage.lazy';
import ArticleDetailPage from './pages/ArticleDetailPage/ArticleDetailPage.lazy';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import UserLoginPage from './pages/UserLoginPage/UserLoginPage.lazy';
import ArticleClusterSearchPage from './pages/ArticleClusterSearchPage/ArticleClusterSearchPage.lazy';
import useAuthApi from './hooks/useAuthApi';
import ArticleClusterDetailPage from './pages/ArticleClusterDetailPage/ArticleClusterDetailPage.lazy';
import ArticleDistributionListPage from './pages/ArticleDistributionListPage/ArticleDistributionListPage';
import ArticleDistributionDetailPage from './pages/ArticleDistributionDetailPage/ArticleDistributionDetailPage';
import WIPDetailPage from './pages/WIPDetailPage/WIPDetailPage';
import CompletedWorkSearchPage from './pages/CompletedWorkSearchPage/CompletedWorkSearchPage.lazy';
import WIPCreatePage from './pages/WIPCreatePage/WIPCreatePage';
import MyWorkPage from './pages/MyWorkPage/MyWorkPage';
import IndexSearchPage from './pages/IndexSearchPage/IndexSearchPage';
import AllWorkPage from './pages/AllWorkPage/AllWorkPage';
import ArticleViewPage from './pages/ArticleViewPage/ArticleViewPage';
import HelpPage from './pages/HelpPage/HelpPage';
import OxygenHelpPage from './pages/OxygenHelpPage/OxygenHelpPage';
import SpecialCharactersPage from './pages/SpecialCharactersPage/SpecialCharactersPage';
import WorkflowDiagramsPage from './pages/WorkflowDiagramsPage/WorkflowDiagramsPage';
import CreateProofPage from './pages/CreateProofPage/CreateProofPage';
import GroupsPage from './pages/GroupsPage/GroupsPage';
import UsersPage from './pages/UsersPage/UsersPage';
import GroupDetailPage from './pages/GroupDetailPage/GroupDetailPage';
import UserDetailPage from './pages/UserDetailPage/UserDetailPage';
import InstitutionalStatusPage from './pages/InstitutionalStatusPage/InstitutionalStatusPage';
import ConsumerStatusPage from './pages/ConsumerStatusPage/ConsumerStatusPage';
import InternationalStatusPage from './pages/InternationalStatusPage/InternationalStatusPage';
import BDSStatusPage from './pages/BDSStatusPage/BDSStatusPage';
import SyndicationStatusPage from './pages/SyndicationStatusPage/SyndicationStatusPage';
import FeatureSearchPage from './pages/FeatureSearchPage/FeatureSearchPage';
import FeatureCreatePage from './pages/FeatureCreatePage/FeatureCreatePage';
import FeatureDetailPage from './pages/FeatureDetailPage/FeatureDetailPage';
import FeatureViewPage from './pages/FeatureViewPage/FeatureViewPage';
import RecentArticlesPage from './pages/RecentArticlesPage/RecentArticlesPage';
import RecentFeaturesPage from './pages/RecentFeaturesPage/RecentFeaturesPage';
import StylebookPage from './pages/StylebookPage/StylebookPage';

const AppRoutes = () => {
  const history = useHistory();
  const location = useLocation();
  const { checkIfUserIsAuthenticated, userDataFromStore } = useAuthApi();

  // Scroll to top on route change
  useEffect(() => history.listen(() => window.scrollTo(0, 0)), [history]);

  // Check if user is authorized on mount and route change.
  useEffect(() => {
    checkIfUserIsAuthenticated();
  }, [checkIfUserIsAuthenticated, location.pathname]);

  return (
    <Switch>
      {/* userDataFromStore = undefined -> not sure if user is authenticated or validated (confirm ticket from cookie is already set and valid) */}
      {/* userDataFromStore = null -> user is not authenticated or validated */}
      {/* userDataFromStore is assigned a user -> user is authenticated or validated */}

      {/* When userDataFromStore equals undefined we need to authenticate the user */}
      {/* if no auth cookies are present or validate when the cookies are present. */}
      {/* Either way this means getting the user data. */}
      {/* We do not want to show the routes until we know we have user data in the store. */}
      {/* While userDataFromStore is undefined we know that the user is being authenticated or validated. */}
      {/* And not until userDataFromStore is null (unauthenticated) or defined (authenticated) */}
      {/* do we return the routes. */}

      {/* Login Page */}
      {/* Is authorized to unauthenticated users only. */}
      <ProtectedRoute
        exact
        redirectTo={location.state?.from ?? Routes.ARTICLE_SEARCH.toLink()}
        captureFromState={false}
        path={Routes.LOGIN.path}
        isAuthorized={!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <UserLoginPage />
      </ProtectedRoute>

      {/* Article Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ARTICLE_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ArticleSearchPage />
      </ProtectedRoute>

      {/* Article Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ARTICLE_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ArticleCreatePage />
      </ProtectedRoute>

      {/* Article Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ARTICLE_DETAIL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ArticleDetailPage />
      </ProtectedRoute>

      {/* Article View Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ARTICLE_VIEW.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ArticleViewPage />
      </ProtectedRoute>

      {/* Clusters Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.CLUSTER_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ArticleClusterSearchPage />
      </ProtectedRoute>

      {/* Cluster Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.CLUSTER_DETAIL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ArticleClusterDetailPage />
      </ProtectedRoute>

      {/* Reports Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.REPORTS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ReportsPage />
      </ProtectedRoute>

      {/* Distribution List Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.DISTRIBUTION.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ArticleDistributionListPage />
      </ProtectedRoute>

      {/* Distribution Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.DISTRIBUTION_DETAIL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ArticleDistributionDetailPage />
      </ProtectedRoute>

      {/* WIP Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.WIP_DETAIL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <WIPDetailPage />
      </ProtectedRoute>

      {/* Completed Work Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.COMPLETED_WORK_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <CompletedWorkSearchPage />
      </ProtectedRoute>

      {/* All Work Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ALL_WORK_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <AllWorkPage />
      </ProtectedRoute>

      {/* All Work Search Page (debug) */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ALL_WORK_SEARCH_DEBUG.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <AllWorkPage />
      </ProtectedRoute>

      {/* WIP Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.WIP_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <WIPCreatePage />
      </ProtectedRoute>

      {/* My Work Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.MY_WORK.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <MyWorkPage />
      </ProtectedRoute>

      {/* My Work Page (debug) */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.MY_WORK_DEBUG.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <MyWorkPage />
      </ProtectedRoute>

      {/* Index Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.INDEX_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <IndexSearchPage />
      </ProtectedRoute>

      {/* Help Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.HELP.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <HelpPage />
      </ProtectedRoute>

      {/* Oxygen Help Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.HELP_OXYGEN.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <OxygenHelpPage />
      </ProtectedRoute>

      {/* Special Characters Help Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.HELP_SPECIAL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <SpecialCharactersPage />
      </ProtectedRoute>

      {/* Workflow Diagrams Help Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.HELP_WORKFLOW.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <WorkflowDiagramsPage />
      </ProtectedRoute>

      {/* Stylebook Help Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.HELP_STYLEBOOK.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <StylebookPage />
      </ProtectedRoute>

      {/* Proof Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.PROOF.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <CreateProofPage />
      </ProtectedRoute>

      {/* Groups Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.GROUPS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <GroupsPage />
      </ProtectedRoute>

      {/* Users Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.USERS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <UsersPage />
      </ProtectedRoute>

      {/* Group Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.GROUP_DETAIL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <GroupDetailPage />
      </ProtectedRoute>

      {/* User Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.USER_DETAIL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <UserDetailPage />
      </ProtectedRoute>

      {/* Institutional Status Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.INSTITUTIONAL_STATUS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <InstitutionalStatusPage />
      </ProtectedRoute>

      {/* Consumer Status Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.CONSUMER_STATUS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ConsumerStatusPage />
      </ProtectedRoute>

      {/* International Status Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.INTERNATIONAL_STATUS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <InternationalStatusPage />
      </ProtectedRoute>

      {/* BDS Status Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.BDS_STATUS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <BDSStatusPage />
      </ProtectedRoute>

      {/* Syndication Status Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.SYNDICATION_STATUS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <SyndicationStatusPage />
      </ProtectedRoute>

      {/* Feature Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.FEATURE_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <FeatureSearchPage />
      </ProtectedRoute>

      {/* Feature Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.FEATURE_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <FeatureCreatePage />
      </ProtectedRoute>

      {/* Feature Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.FEATURE_DETAIL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <FeatureDetailPage />
      </ProtectedRoute>

      {/* Feature View Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.FEATURE_VIEW.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <FeatureViewPage />
      </ProtectedRoute>

      {/* Recently Updated Articles Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.RECENT_ARTICLES.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <RecentArticlesPage />
      </ProtectedRoute>

      {/* Recently Updated Features Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.RECENT_FEATURES.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <RecentFeaturesPage />
      </ProtectedRoute>

      {/* Test Page - for dev environment only */}
      {/* Is authorized to dev env users only. */}
      <ProtectedRoute
        exact
        path={Routes.TEST.path}
        isAuthorized={!!userDataFromStore && ENV !== 'production'}
        isAuthorizing={userDataFromStore === undefined}
      >
        <TestPage />
      </ProtectedRoute>

      {/*
        Redirect back to article search page if no routes above have matched.
      */}
      <Route path="*">
        <Redirect to={Routes.ARTICLE_SEARCH.toLink()} />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
